@import "vars.scss";
.root {
  @apply text-center md:text-left mx-auto flex flex-col md:flex-row items-center gap-8 lg:gap-12 md:my-20;
  .title,
  .description {
    @apply mx-auto;
  }
  .title {
    @apply text-2xl md:text-3xl font-bold mb-3;
  }
  .description {
    @apply text-sm md:text-base text-gray-500 max-w-lg lg:max-w-2xl;
  }
}
