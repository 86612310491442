@import "vars.scss";
.main {
  @apply max-w-xs lg:max-w-md xl:max-w-lg mx-auto;
  :global(#top-part) {
    transform-origin: 1140px 633px;
    animation: rotateHand 5s forwards infinite;
  }
  @keyframes rotateHand {
    0% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(9deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}
